import { Box, FormControl, FormLabel, Theme, Typography, useMediaQuery } from "@mui/material";
import CabinetPage from "../../components/Common/CabinetPage";
import {
  GridColDef, GridEventListener, GridFilterModel, GridSortModel, getGridDateOperators, getGridStringOperators
} from "@mui/x-data-grid-pro";
import { useCallback, useMemo, useState } from "react";
import { Leader, LeaderRelationshipDenorm } from "../../store";
import { Contact, ContactCategory, ContactType } from "../../store/cabinetApi/generated/crm";
import { DateTime } from "luxon";
import CRMTable from '../../components/CRM/CRMTable';
import { CabExecPicker } from "@CabComponents/CabExecPicker";
import CabAvatar from "@CabComponents/CabAvatar";
import { getLeaderIconSrc } from "../../utils/leaderUtils";
import NewContactModal from "../CRMContacts/NewContactModal";
import { CabButton } from "@CabComponents/CabButton";
import { isLargeFormatDevice } from "../../utils/screenSizeUtils";
import SearchInputForm, { SearchForm } from "../../components/CRM/SearchInputForm";
import { CabTooltip } from "@CabComponents/CabTooltip";


const CRMRelationships = ({
  relationships, page, pageSize, rowCount, onPageChange, onNavigateToContact, onCreateContact, filter, 
  onSetFilter, sort, onSetSort, loadingRelationships, onLeaderSelected, selectedLeaderId, leaderOptions,
  contactCategories, contactTypes, isCreatingContact, onSearch
}: {
  relationships: LeaderRelationshipDenorm[];
  contactCategories: ContactCategory[];
  contactTypes: ContactType[];
  isCreatingContact: boolean;
  page: number;
  pageSize: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  onNavigateToContact: (id: LeaderRelationshipDenorm['id']) => void;
  onCreateContact: (contact: Omit<Contact, 'id'>, relationshipLeaders?: Leader['id'][]) => Promise<void>;
  filter?: GridFilterModel;
  onSetFilter: (filter: GridFilterModel | undefined) => void;
  sort?: GridSortModel;
  onSetSort: (sort: GridSortModel | undefined) => void;
  loadingRelationships: boolean;
  selectedLeaderId?: Leader['id'];
  onLeaderSelected: (leaderId: Leader['id']) => void;
  leaderOptions: Leader[];
  onSearch: (search: string) => void;
}) => {
  const [openNewContactModal, setOpenNewContactModal] = useState(false);
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const cabExecOptions = useMemo(() => leaderOptions.map(leader => {
    return {
      value: leader.id,
      label: `${leader.first_name} ${leader.last_name}`,
      icon: <CabAvatar
        src={getLeaderIconSrc(leader)}
        color={leader.color}
        name={`${leader.first_name}
        ${leader.last_name}`}
        size="small"
      />
    };
  }), [leaderOptions]);

  const handleSearch = ({ search }: SearchForm) => {
    onSearch(search);
  };

  return (
    <CabinetPage
      pageName="Relationships"
      headerContent={
        <Box width='100%' display='flex' justifyContent='space-between'>
          <Typography variant="h1">
            Relationships
          </Typography>
          {!isSmDown && (
            <SearchInputForm
              onSearch={handleSearch}
              sx={{ flex: 1, maxWidth: 500, paddingLeft: 4, paddingRight: 4 }}
            />
          )}
          {isLargeFormatDevice() && (
            <CabButton
              onClick={() => setOpenNewContactModal(true)}
            >
              New Contact
            </CabButton>
          )}
        </Box>
      }
    >
      <>
        <Box padding={2} display="flex" flexDirection="column" flex={1}>
          <Box display="flex" justifyContent="space-between">
            <FormControl>
              <FormLabel sx={{ fontWeight: 'bold' }}>Show Relationships with</FormLabel>
              <CabExecPicker
                value={selectedLeaderId || -1}
                options={cabExecOptions}
                onChange={onLeaderSelected}
                placeholder="Select a Teammate"
                sx={{ width: 300, marginTop: '0px !important' }}
              />
            </FormControl>
            {isSmDown && (
              <SearchInputForm onSearch={handleSearch} />
            )}
          </Box>

          <Box position="relative" height="100%">
            <Box position="absolute" top={0} bottom={0} left={0} right={0}>
              <RelationshipTable
                relationships={relationships}
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                onPageChange={onPageChange}
                onNavigateToRelationship={onNavigateToContact}
                filter={filter}
                onSetFilter={onSetFilter}
                sort={sort}
                onSetSort={onSetSort}
                defaultHiddenFields={['title']}
                loading={loadingRelationships}
              />
            </Box>
          </Box>
        </Box>

        <NewContactModal
          open={openNewContactModal}
          onClose={() => setOpenNewContactModal(false)}
          contactCategories={contactCategories}
          contactTypes={contactTypes}
          onCreateContact={onCreateContact}
          isCreatingContact={isCreatingContact}
          leaderOptions={leaderOptions}
        />
      </>
    </CabinetPage>
  );
};

const relationshipColumns: GridColDef<LeaderRelationshipDenorm>[] = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    width: 200,
    display: "flex",
    filterOperators: getGridStringOperators().filter(op => !['isAnyOf', 'isEmpty', 'isNotEmpty'].includes(op.value)),
    valueGetter: (value, row, column, apiRef) => row.contact?.name || '',
  },
  {
    field: 'title',
    headerName: 'Title',
    type: 'string',
    width: 200,
    display: "flex",
    valueGetter: (value, row, column, apiRef) => row.contact?.title || '',
  },
  {
    field: 'primary_email',
    headerName: 'Email',
    type: 'string',
    width: 200,
    display: "flex",
    valueGetter: (value, row, column, apiRef) => row.contact?.primary_email || '',
  },
  {
    field: 'prev_meeting_data',
    headerName: 'Last Meeting',
    type: 'dateTime',
    width: 200,
    display: "flex",
    valueGetter: (value, row, column, apiRef) => (
      row.prev_meeting_data?.['v1_15']
        ? DateTime.fromISO(row.prev_meeting_data['v1_15'].start_date).toJSDate()
        : undefined
    ),
    renderCell: (params) => {
      const tooltip = params.row.prev_meeting_data?.['v1_15']
        ? `${params.row.prev_meeting_data['v1_15'].title}\n${params.formattedValue}`
        : '';
      return (
        <CabTooltip title={tooltip} wrapWithSpan>
          {params.formattedValue}
        </CabTooltip>
      );
    }
  },
  {
    field: 'next_meeting_data',
    headerName: 'Next Meeting',
    type: 'dateTime',
    width: 200,
    display: "flex",
    valueGetter: (value, row, column, apiRef) => (
      row.next_meeting_data?.['v1_15']
        ? DateTime.fromISO(row.next_meeting_data['v1_15'].start_date).toJSDate()
        : undefined
    ),
    renderCell: (params) => {
      const tooltip = params.row.next_meeting_data?.['v1_15']
        ? `${params.row.next_meeting_data['v1_15'].title}\n${params.formattedValue}`
        : '';
      return (
        <CabTooltip title={tooltip} wrapWithSpan>
          {params.formattedValue}
        </CabTooltip>
      );
    }
  },
  {
    field: 'relationship_strength',
    headerName: 'Relationship Strength',
    type: 'string',
    width: 170,
    display: "flex",
  },
  // {
  //   field: 'company',
  //   headerName: 'Company',
  //   type: 'singleSelect',
  //   sortable: false,
  //   width: 200,
  //   renderCell: (params) => {
  //     return params.row.company?.name ?? "";
  //   },
  //   filterOperators: getGridSingleSelectOperators().filter(op => op.value !== 'not'),
  //   valueGetter: (value: Company | null | undefined, row, column, apiRef) => value?.id,
  // },
  {
    field: 'contact_created_at',
    headerName: 'Created On',
    type: 'dateTime',
    width: 200,
    display: "flex",
    filterOperators: getGridDateOperators(false).filter(op => ['onOrAfter', 'onOrBefore'].includes(op.value)),
    valueGetter: (value, row, column, apiRef) => (
      row.contact?.created_at ? DateTime.fromISO(row.contact.created_at).toJSDate() : undefined
    ),
    valueFormatter: (value, row, column, apiRef) => (
      value ? DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_MED) : ''
    ),
  },
];

export const RelationshipTable = ({
  relationships, onNavigateToRelationship, page, pageSize, rowCount, onPageChange, filter, onSetFilter,
  sort, onSetSort, loading, defaultHiddenFields
}: {
  relationships: LeaderRelationshipDenorm[];
  page: number;
  pageSize: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  onNavigateToRelationship: (id: LeaderRelationshipDenorm['id']) => void;
  filter?: GridFilterModel;
  onSetFilter: (filter: GridFilterModel | undefined) => void;
  sort?: GridSortModel;
  onSetSort: (filter: GridSortModel | undefined) => void;
  defaultHiddenFields?: string[];
  loading: boolean;
}) => {
  const handleCellClick = useCallback<GridEventListener<'cellClick'>>((params, event, details) => {
    if (params.field === 'name' && params.row.contact) {
      onNavigateToRelationship(params.row.contact.id);
    }
  }, [onNavigateToRelationship]);

  return <CRMTable
    columns={relationshipColumns}
    rows={relationships}
    page={page}
    pageSize={pageSize}
    rowCount={rowCount}
    onPageChange={onPageChange}
    onCellClick={handleCellClick}
    filter={filter}
    onSetFilter={onSetFilter}
    sort={sort}
    onSetSort={onSetSort}
    loading={loading}
    defaultHiddenFields={defaultHiddenFields}
  />;
};


export default CRMRelationships;
